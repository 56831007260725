export const vendorConfig = {
  headings: [
   
    {
      headingLabel: "Version Number",
      icon: "",
      width: "25%",
      inputType: "text",
    },
    {
      headingLabel: "Title",
      icon: "",
      width: "10%",
      inputType: "text",
    },
   
  ],
  dataNodes: [
    "tax_id",
    "vendor_name",
    
    
  ],
  primaryKey: "vendor_id",
  actions: ["pen", "times"],
  nodeFields: [
    {
      column: "is_active",
      icon: "check",
    },
  ],
  buttonFields: [
    {
      column: "",
      icon: "pen",
    },
  ],
};

export const vendorList = [
  {
    inactive: "yes",
    name: "[Vendor Name]",
    taxId: "[tax id]",
    ap_number: "[ap number]",
    phone: "[phone]",
    location: "[location]",
    notes: "[notes]",
    id: 1,
  },
  {
    inactive: "yes",
    name: "[Vendor Name]",
    taxId: "[tax id]",
    ap_number: "[ap number]",
    phone: "[phone]",
    location: "[location]",
    notes: "[notes]",
    id: 2,
  },
  {
    inactive: "yes",
    name: "[Vendor Name]",
    taxId: "[tax id]",
    ap_number: "[ap number]",
    phone: "[phone]",
    location: "[location]",
    notes: "[notes]",
    id: 3,
  },
  {
    inactive: "yes",
    name: "[Vendor Name]",
    taxId: "[tax id]",
    ap_number: "[ap number]",
    phone: "[phone]",
    location: "[location]",
    notes: "[notes]",
    id: 4,
  },
  {
    inactive: "yes",
    name: "[Vendor Name]",
    taxId: "[tax id]",
    ap_number: "[ap number]",
    phone: "[phone]",
    location: "[location]",
    notes: "[notes]",
    id: 5,
  },
];
