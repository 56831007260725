import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import { withUserContext } from "../../contexts/UserContext";
import _ from "lodash";
import { MDBCard, MDBCardBody, MDBCardText } from "mdb-react-ui-kit";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import BasicLabel from "../SharedComponents/BasicLabel/BasicLabel";
import "./ShowDetails.scss";
import BasicButton from "../../components/SharedComponents/BasicButton/BasicButton";
import SelectField from "../../components/SharedComponents/SelectField/SelectField";
import SearchSelectField from "../../components/SharedComponents/SearchSelectField";
import BasicTabs from "../../components/SharedComponents/BasicTabs/BasicTabs";
import TableComponent from "../../components/SharedComponents/Table";
import TablePagination from "@material-ui/core/TablePagination";
import {
  handlePaginationList,
  updateTableSortConfigObject,
  tableSortList,
} from "../../Common/TableHelper";
import { Tabs, poListConfig, tablePOData, Vendors } from "./config";
import PODetails from "../PODetails/PODetails";
import CommitsDetail from "../CommitsDetail/CommitsDetail";
import PostWatchService from "../../services/service";
import * as Constants from "../../constants/constants";
import axios from "axios";
import Modal from "../../components/SharedComponents/Modal/Modal";

class ShowDetails extends React.Component {
  constructor(props) {
    super(props);
    this.dashboardPOSelectedId = null;
    this.state = {
      showDetails: null,
      showDetailListSorted: [],
      seasons: null,
      episodes: null,
      poHeaders: null,
      selectedSeason: null,
      selectedEpisode: null,
      selectedVendor: null,
      myVendors: [],
      vendors: null,
      currentTab: 1,
      currentList: PODetails,
      tablePOData: tablePOData,
      selectedPoOrder: null,
      rowsPerPage: 9,
      page: 1,
      totalVendorsCount: null,
      isFetchingShowDetails: false,
      isFetchingVendors: false,
      isFetchingSeasons: false,
      isFetchingPOHeaders: false,
      isFetchingMyVendors: false,
      searchCancelToken: null,
      prodPage: 0,
      rowsProdPerPage: 2,
      showExportPOModal: false,
      showEditPORangeModal: false,
      openShowModal: false,
      sortBy: null,
      sortOrder: "asc",
      showOrientationModal: false,
      reportData: null,
      loadingNotRequired:false
    };
  }

  componentDidMount() {
    this.checkManageUser();
    let dashboardPO = this.props.location.state;
    //let dashboardPO = this.props.location;
    if (dashboardPO) {
      this.getShowDetails(dashboardPO.show_id);
      this.props?.userContext?.setCurrentShowSelected(dashboardPO.show_id);
      this.getSeasons(dashboardPO.show_id, dashboardPO);
      this.dashboardPOSelectedId = dashboardPO?.po_id;
      this.setState({
        selectedEpisode: dashboardPO.episode,
        // selectedPoOrder: dashboardPO.,
      });
    } else {
      this.props.history.push("/");
    }
  }
  checkManageUser = () => {
    let userPermissions = this.props?.userContext?.active_tenant?.permissions;
    console.log("userPermissions ", this.props?.userContext?.active_tenant);
    if (userPermissions?.isManageUser) {
      this.props.history.push("/usermanagement");
    }
  };

  getShowDetails = (showId) => {
    this.setState({ isFetchingShowDetails: true });
    PostWatchService.getData(
      Constants.postWatchServiceBaseUrl + "/getshowdetails?showId=" + showId,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        this.setState({
          showDetails: response.data[0],
          isFetchingShowDetails: false,
          prodCompaniesList: response.data[0].prod_companies,
          showDetailListSorted: handlePaginationList(
            0,
            this.state.rowsPerPage,
            response.data[0].prod_companies.slice(0, 2)
          ),
        });
      },
      (err) => {
        this.setState({
          isFetchingShowDetails: false,
        });
      }
    );
  };

  getSeasons = (showId, dashboardPO) => {
    this.setState({ isFetchingSeasons: true });
    PostWatchService.getData(
      Constants.postWatchServiceBaseUrl + "/getshowepisodes?showId=" + showId,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        this.setState(
          {
            seasons: response.data[0]?.show_season,
            isFetchingSeasons: false,
          },
          () => {
            this.getMyVendors();
            this.setState(
              {
                selectedSeason: {
                  show_season_id: dashboardPO.show_id,
                  season_id: dashboardPO.season_id,
                },
              },
              () => {
                /* handleSeasonChange is called when season is changed in that case all episodes data should be shown,
                but here selected episode which is selected in dashboard should be shown, so pass episode_id */
                this.handleSeasonChange(
                  dashboardPO.season_id,
                  this.state.selectedEpisode?.episode_id
                );
                this.props?.userContext?.setCurrentSeasonSelected(
                  dashboardPO.season_id
                );
              }
            );
          }
        );
      },
      (err) => {
        this.setState({ isFetchingSeasons: false });
      }
    );
  };

  getPORangeLabel = (showSeasonId) => {
    PostWatchService.getData(
      Constants.postWatchServiceBaseUrl +
        "/getshowseasonporange?showSeasonId=" +
        showSeasonId,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        this.props.userContext.setPoRangeLabel(response.data[0]?.po_range);
      },
      (err) => {}
    );
  };

  searchVendors = (e) => {
    if (this.state.searchCancelToken != null)
      this.state.searchCancelToken.cancel(
        "Operation canceled due to new request"
      );
    var cancelToken = axios.CancelToken.source();
    this.setState({ searchCancelToken: cancelToken });
    if (e.target.value !== null) {
      this.setState({ isFetchingVendors: true });
      PostWatchService.getDataWithCancel(
        Constants.postWatchServiceBaseUrl +
          `/getvendorsbyname?vendorName=${e.target.value}&userId=${this.props?.userContext?.user_profile?.user_id}&showId=${this.props.location.state?.show_id}`,
        cancelToken,
        this.props?.userContext?.active_tenant?.tenant_id,
        1
      ).then(
        (response) => {
          this.setState({
            vendors: response.data,
            isFetchingVendors: false,
          });
        },
        (err) => {
          this.setState({
            isFetchingVendors: false,
          });
        }
      );
    }
  };

  getPOHeaders = (id) => {
    if (id) {
      this.setState({ isFetchingPOHeaders: true });
      PostWatchService.getData(
        Constants.postWatchServiceBaseUrl +
          "/getpoheaders?episodeId=" +
          id +
          "&showSeasonId=" +
          this.state?.selectedSeason?.show_season_id +
          "&sortBy=" +
          this.state.sortBy +
          "&sortOrder=" +
          this.state.sortOrder,
        this.props?.userContext?.active_tenant?.tenant_id
      ).then(
        (response) => {
          this.setState(
            {
              poHeaders: response.data,
              isFetchingPOHeaders: false,
            },
            () => {
              this.navigatePOdetails();
            }
          );
        },
        (err) => {
          this.setState({
            isFetchingPOHeaders: false,
          });
        }
      );
    }
  };

  navigatePOdetails = () => {
    // Navigate to PO details page if po number is selected in dashboard table and this should happen only for the first time
    // thats why setting dashboardPOSelectedId to null
    if (this.dashboardPOSelectedId) {
      let item = _.find(this.state.poHeaders, {
        po_id: this.dashboardPOSelectedId,
      });
      if (item) {
        this.dashboardPOSelectedId = null;
        this.handlePOSelection(item);
      }
    }
  };

  getEpisodes = (seasonId) => {
    let season = _.find(this.state.seasons, { season_id: seasonId });
    if (season) {
      this.setState({ episodes: season?.episodes });
    }
  };

  handleVendorChange = (vendor) => {
    this.setState({
      selectedVendor: vendor,
    });
  };

  handleSeasonChange = (value, episodeId) => {
    let season = _.find(this.state.seasons, { season_id: value });
    if (season) {
      this.setState(
        {
          selectedSeason: season,
        },
        () => {
          // -1 is for all All Episodes
          this.onEpisodeClick({
            episode_id: episodeId || -1,
          });
          this.getEpisodes(season?.season_id);
          this.getPORangeLabel(this.state?.selectedSeason?.show_season_id);
        }
      );
    }
  };
  addMyVendors = () => {
    // Duplicate check and then insert
    this.setState({ isFetchingMyVendors: true });
    if (
      this.state.selectedVendor &&
      !_.find(this.state.myVendors, {
        vendor_id: this.state.selectedVendor.value,
      })
    ) {
      this.updateMyVendors(this.state.selectedVendor.value, 0);
    } else {
      this.setState({ isFetchingMyVendors: false });
    }
  };

  handleChipDelete = (item) => {
    this.updateMyVendors(item.vendor_id, 1);
  };

  updateMyVendors = (vendorId, isDelete) => {
    this.setState({ isFetchingMyVendors: true });
    PostWatchService.postData(
      Constants.postWatchServiceBaseUrl +
        `/postuservendor?userId=${this.props?.userContext?.user_profile?.user_id}&showId=${this.props.location.state?.show_id}&isDelete=${isDelete}&vendorId=${vendorId}`,
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        this.getMyVendors();
        this.setState({
          selectedVendor: null,
          isFetchingMyVendors: false,
          vendors: [],
        });
      },
      (err) => {
        this.setState({ isFetchingMyVendors: false });
      }
    );
  };

  getMyVendors = () => {
    this.setState({ isFetchingMyVendors: true });
    PostWatchService.getDataParams(
      Constants.postWatchServiceBaseUrl +
        `/getuservendors?userId=${this.props?.userContext?.user_profile?.user_id}&showId=${this.props.location.state?.show_id}&pageNo=${this.state.page}&pageCount=${this.state.rowsPerPage}`,'',
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        this.setState({
          myVendors: response.data.records,
          totalVendorsCount: response.data.total_records_count,
          isFetchingMyVendors: false,
        });
      },
      (err) => {
        this.setState({
          isFetchingMyVendors: false,
        });
      }
    );
  };

  handleTabChange = (e, id) => {
    this.setState({ currentTab: id });
  };
  handleChangePage = (event, newPage) => {
    this.setState(
      {
        page: newPage + 1,
      },
      () => {
        this.getMyVendors();
      }
    );
  };
  handleProdChangePage = (event, newPage) => {
    this.setState({
      prodPage: newPage,
      showDetailListSorted: handlePaginationList(
        newPage,
        this.state.rowsProdPerPage,
        this.state.prodCompaniesList
      ),
    });
  };

  handlePOSelection = (item) => {
    this.setState({ selectedPoOrder: item });
  };
  backToPO = () => {
    this.setState({ selectedPoOrder: null });
    this.getPOHeaders(this.state.selectedEpisode?.episode_id);
  };

  newPOClick = () => {
    /* Since New PO button is show in both views(PO and POLines), when PO button is clicked in PO Lines screen it will not re-ender, 
    so in order to re - render PODetails, set to null first then to 1 
    selectedPoOrder -1 for new PO
    */
    this.setState({ currentTab: null }, () => {
      let selectedEpisode = { ...this.state.selectedEpisode };
      // New PO created always goes under All Episodes and not under any other selected episode, so selected should be 'All Episodes' while creating a new PO
      selectedEpisode.episode_id = -1;
      this.setState({ currentTab: 1, selectedPoOrder: -1, selectedEpisode });
    });
  };

  handleFileDownLoad = (dataItem) => {
    console.log("dataItem ", dataItem);
  };

  onEpisodeClick = (episode) => {
    if (episode) {
      this.setState({
        selectedEpisode: episode,
        selectedPoOrder: null,
      });
      this.getPOHeaders(episode?.episode_id);
    }
    // To force re-render the current tab to get updated values upon episode click
    let state = { ...this.state };
    this.setState({ currentTab: null }, () => {
      this.setState({ currentTab: state.currentTab });
    });
  };

  getPosition = (val) => {
    switch (val) {
      case "PO #":
        return 0;
      case "Date":
        return 1;
      case "Vendor":
        return 2;
      case "Total Cost":
        return 3;
      case "Extended Cost":
        return 4;
      case "Revision Notes":
        return 5;
      case "Created By":
        return 6;
      default:
        return 0;
    }
  };

  getColumnName = (val) => {
    switch (val) {
      case "PO #":
        return "po_number";
      case "Date":
        return "created_at";
      case "Vendor":
        return "vendor_name";
      case "Total Cost":
        return "total_cost";
      case "Extended Cost":
        return "extended_cost";
      case "Created By":
        return "full_name";
      case "Revision Notes":
        return "notes";
      default:
        return 0;
    }
  };

  sortColumn = (val) => {
    let Position = this.getPosition(val);
    let col_name = this.getColumnName(val);
    // let sortList = this.state.poHeaders;
    let order = null;
    if (this.state.a_z && poListConfig?.headings[Position]?.icon != "") {
      // sortList.reverse();
      if (poListConfig?.headings[Position]?.icon == "sort-alpha-up") {
        poListConfig?.headings.map((item) => {
          item.icon = "";
        });
        poListConfig.headings[Position].icon = "sort-alpha-down";
      } else {
        poListConfig?.headings.map((item) => {
          item.icon = "";
        });
        poListConfig.headings[Position].icon = "sort-alpha-up";
      }
      order = "desc";
      this.setState({ a_z: false });
    } else {
      // sortList.sort((a, b) =>
      //   a[col_name]?.toString().split(" ").join("").trim().toLowerCase() >
      //   b[col_name]?.toString().split(" ").join("").trim().toLowerCase()
      //     ? -1
      //     : 1
      // );
      poListConfig?.headings.map((item) => {
        item.icon = "";
      });
      this.setState({ a_z: true });
      poListConfig.headings[Position].icon = "sort-alpha-up";
      order = "asc";
    }
    this.setState({ sortBy: col_name, sortOrder: order }, () => {
      this.getPOHeaders(this.state.selectedEpisode?.episode_id);
    });
  };

  exportPO = () => {
    this.setState({ showExportPOModal: true });
  };
  onExportClick = (items) => {
    console.log("export itemes ", items);
  };

  downloadUrl = (url, reportName) => {
    window.open(url, "_blank");
  };

  onPORangeClick = () => {
    this.setState({ showEditPORangeModal: true });
  };
  onSavePORange = () => {};

  editShow = () => {
    this.setState({ openShowModal: true });
  };

  onShowModalClose = () => {
    this.setState({ openShowModal: false,loadingNotRequired:true });
    this.getShowDetails(this.props.location.state.show_id);
    this.getSeasons(
      this.props.location.state.show_id,
      this.props.location.state
    );
  };

  toggleOrientationModalVisibality = (dataItem) => {
    console.log(dataItem);
    this.setState({
      showOrientationModal: !this.state.showOrientationModal,
      reportData: dataItem,
    });
  };
  render() {
    let isLoading =
     !this.state.loadingNotRequired && (this.state.isFetchingShowDetails || this.state.isFetchingSeasons);
    return (
      <div className="showDetails">
        {isLoading ? (
          <div className="ContentLoader">
            <CircularProgress />
          </div>
        ) : (
          <>
            {this.state.showOrientationModal && (
              <Modal
                open={this.state.showOrientationModal || false}
                modalName={"Orientation"}
                comfirmModalTitle={"Orientation"}
                onClose={this.toggleOrientationModalVisibality}
                po_id={this.state.reportData?.po_id}
                url="/getexportpodetails?reportName=po_report_"
                {...this.props}
              />
            )}
            <MDBRow>
              <MDBCol md={2}>
                <span className="c-black show-name">
                  {this.state.showDetails?.show_name} (
                  {this.state.showDetails?.po_prefix})
                </span>
              </MDBCol>
              <MDBCol md={2}>
                <SelectField
                  value={this.state?.selectedSeason?.season_id || null}
                  className={"transparent-select "}
                  options={this.state.seasons?.map((item) => {
                    return {
                      value: item.season_id,
                      label: item.season_name,
                    };
                  })}
                  onChange={(e, value) =>
                    this.handleSeasonChange(e.target.value)
                  }
                  placeHolderText="Select Season"
                />
              </MDBCol>
              <MDBCol md={7}>
                <div className="c-black show-note">Show Note</div>
                <div className=" c-black show-details">
                  {this.state.showDetails?.notes}
                </div>
              </MDBCol>
              {this.props?.userContext?.active_tenant?.permissions?.isAdmin && (
                <MDBCol md={1} className="text-end">
                  <BasicButton
                    variant="outlined"
                    text="Show"
                    onClick={() => this.editShow()}
                    icon={"pen"}
                  />
                </MDBCol>
              )}
            </MDBRow>

            <MDBRow className="mt-3">
              <MDBCol md={4}>
                <div className="wb-sub-heading">Production Companies</div>
                <MDBCard className="">
                  <MDBCardBody>
                    <MDBCardText>
                      {this.state?.showDetailListSorted?.map((company) => (
                        <MDBRow className="mb-3">
                          <div className="comp-name c-black">
                            {company?.comp_name}
                          </div>
                          <MDBCol md={8}>
                            <div className="comp-address c-black">
                              {company?.address ? (
                                company.address
                              ) : (
                                <span className="no-address-label">
                                  {" "}
                                  No Address
                                </span>
                              )}
                            </div>
                            <div className=" comp-address c-black">
                              {company?.city} &nbsp;
                              {company?.state}&nbsp; {company?.zip}
                            </div>
                          </MDBCol>
                          <MDBCol md={4} className="text-end">
                            <div className="curr-code c-black">
                              {company?.currency_code}
                            </div>
                            <div className="curr-code c-black">
                              {company?.exchange_rate}
                            </div>
                          </MDBCol>
                        </MDBRow>
                      ))}
                      <TablePagination
                        component="div"
                        count={
                          this.state.showDetails?.prod_companies
                            ? this.state.showDetails?.prod_companies?.length
                            : 0
                        }
                        rowsPerPageOptions={[10, 20, 30]}
                        page={this.state.prodPage}
                        className={"hide-noofrows-dropdown"}
                        hideNoOfPagesPerRow={true}
                        onChangePage={this.handleProdChangePage}
                        rowsPerPage={this.state.rowsProdPerPage}
                      />
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>

              <MDBCol md={8}>
                <div className="wb-sub-heading">My Vendors</div>
                <MDBCard className="">
                  <MDBCardBody>
                    <MDBCardText>
                      {this.state.myVendors?.length >= 0 && (
                        <>
                          <MDBRow className="my-vendors-conatiner">
                            {this.state.isFetchingMyVendors ? (
                              <div className="ContentLoader">
                                <CircularProgress />
                              </div>
                            ) : (
                              this.state.myVendors?.map((item) => {
                                return (
                                  <>
                                    <MDBCol md={4} className="mb-2">
                                      <Chip
                                        label={item?.vendor_name}
                                        size="small"
                                        onDelete={() =>
                                          this.handleChipDelete({ ...item })
                                        }
                                        variant="outlined"
                                      />
                                    </MDBCol>
                                  </>
                                );
                              })
                            )}
                            {this.state.myVendors?.length === 0 && (
                              <span>No Vendors added</span>
                            )}
                          </MDBRow>
                        </>
                      )}

                      <MDBRow className="mt-2">
                        <MDBCol md={4}>
                          <SearchSelectField
                            id={"key27"}
                            detail_selected={this.state?.selectedVendor}
                            options={this.state.vendors?.map((item) => ({
                              text: item.vendor_name,
                              value: item.vendor_id,
                              is_active: item.is_favourite ? 0 : item.is_active,
                              is_favourite: item.is_favourite,
                            }))}
                            loading={this.state.isFetchingVendors}
                            width="100%"
                            multiple={false}
                            searchSelect={true}
                            searchText={this.searchVendors}
                            showPinIcon="true"
                            placeholder="- Type to search vendors -"
                            noOptionsText="No results"
                            valueSelected={(e, newValue) => {
                              this.setState({
                                selectedVendor: newValue,
                              });
                            }}
                          />
                        </MDBCol>
                        <MDBCol md={1} className="vendor-plus">
                          <BasicButton
                            className="m-1 vendor-plus-bt"
                            variant="outlined"
                            icon={"plus"}
                            disabled={
                              this.state.isFetchingMyVendors ||
                              !this.state?.selectedVendor
                            }
                            onClick={this.addMyVendors}
                          />
                        </MDBCol>
                        <MDBCol md={7}>
                          <TablePagination
                            component="div"
                            count={
                              this.state.totalVendorsCount
                                ? this.state.totalVendorsCount
                                : 0
                            }
                            rowsPerPageOptions={[10, 20, 30]}
                            page={this.state.page - 1}
                            className={"hide-noofrows-dropdown"}
                            hideNoOfPagesPerRow={true}
                            onChangePage={this.handleChangePage}
                            rowsPerPage={this.state.rowsPerPage}
                          />
                        </MDBCol>
                      </MDBRow>
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol md={6} className="showdetails-tab-container">
                <BasicTabs
                  value={this.state?.currentTab}
                  variant={"scrollable"}
                  scrollButtons={"auto"}
                  tabList={Tabs}
                  onChange={(e, tabId) => {
                    this.handleTabChange(e, tabId);
                  }}
                />
              </MDBCol>

              <MDBCol
                md={6}
                className="d-flex justify-content-end align-items-center"
              >
                <span className="f-85rem c-black me-3">
                  PO Range:{" "}
                  {this.props?.userContext?.poRangeLabel
                    ? this.props?.userContext?.poRangeLabel
                    : "Not Available"}
                </span>
                {this.props?.userContext?.active_tenant?.permissions
                  ?.isAdmin && (
                  <BasicButton
                    className="me-3"
                    variant="outlined"
                    text="PO Range"
                    icon={"pen"}
                    onClick={this.onPORangeClick}
                  />
                )}
                <BasicButton
                  className="me-3"
                  text="All PO's"
                  icon={"file"}
                  onClick={this.exportPO}
                />
                <BasicButton
                  text="PO"
                  icon={"plus"}
                  onClick={this.newPOClick}
                />
              </MDBCol>
            </MDBRow>

            <MDBRow className="mt-3">
              <MDBCol md={2}>
                <MDBCard className="all-episodes-main-container">
                  <MDBCardBody>
                    <MDBCardText>
                      <MDBRow>
                        <div
                          className={`f-85rem c-black mb-2 all-episodes-container ${
                            this.state.selectedEpisode?.episode_id === -1
                              ? "selected-episode"
                              : ""
                          } `}
                          onClick={() =>
                            this.onEpisodeClick({
                              episode_id: -1,
                              episode_name: "All Episodes",
                            })
                          }
                        >
                          All Episodes
                        </div>
                        <div className="f-75rem c-black all-episodes-container">
                          {this.state.episodes?.map((obj) => (
                            <MDBCol md={12} className="mb-2">
                              <div
                                className={
                                  obj?.episode_id ===
                                  this.state.selectedEpisode?.episode_id
                                    ? "selected-episode"
                                    : ""
                                }
                                onClick={() => this.onEpisodeClick(obj)}
                              >
                                <div>{obj?.gl_number}</div>
                                <div>{obj?.episode_name}</div>
                              </div>
                            </MDBCol>
                          ))}

                          {/* <MDBCol md={12} className="mb-2">
                            <div>T29573853</div>
                            <div>We Became What We.... Frank!</div>
                          </MDBCol> */}
                        </div>
                      </MDBRow>
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
              <MDBCol md={10} className="mb-4">
                <>
                  {this.state.currentTab === 1 && !this.state.selectedPoOrder && (
                    <div className="PODetailsTable" id="table-bg-sync">
                      <TableComponent
                        list={this.state?.poHeaders || []}
                        config={poListConfig}
                        tableMode={"light"}
                        isLoading={this.state.isFetchingPOHeaders || false}
                        sortCallback={this.sortColumn}
                        hyperLinkNavigationCallback={(item, node) =>
                          this.handlePOSelection(item)
                        }
                        handleFileDownLoad={this.handleFileDownLoad}
                        handleRowFileSelect={
                          this.toggleOrientationModalVisibality
                        }
                        maxHeight={"400"}
                      />
                    </div>
                  )}
                  {this.state.currentTab === 1 &&
                    this.state.selectedPoOrder && (
                      <PODetails
                        poOrder={this.state.selectedPoOrder}
                        episodes={this.state.episodes}
                        selectedSeason={this.state.selectedSeason}
                        selectedEpisode={this.state.selectedEpisode}
                        show_id={this.props.location.state?.show_id}
                        backToPO={this.backToPO}
                        companies={this.state.showDetails?.prod_companies}
                        preFix={this.state.showDetails?.po_prefix}
                        myVendors={this.state?.myVendors}
                        {...this.props}
                      ></PODetails>
                    )}
                  {this.state.currentTab === 2 && (
                    <CommitsDetail
                      show_season_id={
                        this.state?.selectedSeason?.show_season_id
                      }
                      selectedEpisode={this.state.selectedEpisode}
                      selectedSeason={this.state.selectedSeason}
                    ></CommitsDetail>
                  )}
                </>
              </MDBCol>
            </MDBRow>
          </>
        )}
        {this.state?.showExportPOModal && (
          <Modal
            open={this.state.showExportPOModal || false}
            modalName={"ExportPO"}
            comfirmModalTitle={"Export PO's"}
            onClose={() => {
              this.setState({ showExportPOModal: false });
            }}
            onExport={this.onExportClick}
            selectedEpisode={this.state.selectedEpisode}
            selectedSeason={this.state?.selectedSeason}
          />
        )}
        {this.state?.showEditPORangeModal && (
          <Modal
            open={this.state?.showEditPORangeModal || false}
            modalName={"EditPORange"}
            comfirmModalTitle={"Edit PO Range"}
            showName={this.state.showDetails?.show_name}
            selectedSeason={this.state.selectedSeason}
            showDetails={this.state.showDetails}
            onClose={() => {
              this.setState({ showEditPORangeModal: false });
            }}
            onSave={this.onSavePORange}
          />
        )}

        <Modal
          open={this.state.openShowModal || false}
          modalName={"Shows"}
          selectedShow={this.props.location.state}
          comfirmModalTitle={"Modify Show"}
          onClose={this.onShowModalClose}
        />
      </div>
    );
  }
}

export default withUserContext(ShowDetails);
