export const AdminSetupScreenList = [
  { label: "Chart of Account", value: 1 },
  { label: "Production Companies", value: 2 },
  { label: "Set Units", value: 3 },
  { label: "Vendors", value: 4 },
  { label: "Shows", value: 5 },
  { label: "Currencies", value: 6 },
  { label: "Seasons", value: 7 },
  { label: "Versions", value: 8 }
];
