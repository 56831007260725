import React from "react";
import { MDBRow, MDBCol, MDBContainer } from "mdbreact";
import { withUserContext } from "../../../contexts/UserContext";
import BasicLabel from "../../../components/SharedComponents/BasicLabel/BasicLabel";
import BasicButton from "../../../components/SharedComponents/BasicButton/BasicButton";
import IconTextField from "../../../components/SharedComponents/IconTextField/IconTextField";
import TablePagination from "@material-ui/core/TablePagination";
import TableComponent from "../../../components/SharedComponents/Table";
import { vendorList, vendorConfig } from "./Config";
import Modal from "../../../components/SharedComponents/Modal/Modal";
import "./Vendors.scss";
import * as Constants from "../../../constants/constants";
import PostWatchService from "../../../services/service";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  validateCharctersNumberSpecialCharOnly,
  validateNumberSpecialCharOnly,
} from "../../../Common/Helper";
import MessageModal from "../../../components/SharedComponents/MessageModal";
import Message from "../../../Common/Messages.json";

class Versions extends React.Component {
  constructor(props) {
    super(props);
    this.deleteItem = null;
    this.state = {
      postInitiated: false,
      renderList: [],
      modifyVendor: false,
      isSubmit: false,
      isLoading: false,
      tableListSorted: [],
      filters: { vendor_name: null },
      page: 1,
      rowsPerPage: 10,
      totalRows: 0,
      selectedVendor: null,
      mode: null,
      sortBy: null,
      sortOrder: "asc",
      a_z: false,
      config: JSON.parse(JSON.stringify({ ...vendorConfig })),
      isMessageModalOpen: false,
      isErrorMessageModalOpen: false,
    };
  }
  handleOnChange = (field, value) => {
    this.setState((prevState) => ({
      userPostJson: {
        ...prevState.userPostJson,
        [field]: value,
      },
    }));
  };

  componentDidMount() {
    this.getVendorDetails();
  }
  handleInlineEdits = (id, dataItem, mode = null) => {
    this.mode = mode;
    this.setState({
      modifyVendor: !this.state.modifyVendor,
      selectedVendor: dataItem,
    });
  };

  saveInlineEdits = (data, index) => {
    this.postData(data[index]);
  };

  postData = (data) => {
    this.setState({ isPosting: true });
    PostWatchService.postDataParams(
      Constants.postWatchServiceBaseUrl +
        `/postvendors?userId=` +
        this.props?.userContext?.user_profile?.user_id,
      data,
      1,
      1
    ).then(
      (response) => {
        if (response.data[0]?.Level === "Error") {
          this.setState({
            isErrorMessageModalOpen: true,
            isMessageModalOpen: false,
            isPosting: false,
          });
        } else {
          this.setState({
            isMessageModalOpen: false,
            isPosting: false,
            postInitiated: false,
          });
          this.getVendorDetails();
          this.deleteItem = null;
        }
      },
      (err) => {
        this.setState({ isPosting: false });
        console.log("Post agency details error: " + err);
      }
    );
  };

  getVendorDetails = () => {
    let filters = { ...this.state.filters };
    filters.page_count = this.state.rowsPerPage;
    filters.page_no = this.state.page;
    filters.sort_by = this.state.sortBy;
    filters.sort_order = this.state.sortOrder;
    this.setState({
      isLoading: true,
      isSubmit: true,
      searchTerm: null,
      filters,
    });
    PostWatchService.getDataWithFilters(
      Constants.postWatchServiceBaseUrl + "/getvendors",
      filters,
      this.props?.userContext?.active_tenant?.tenant_id
    )
      .then((res) => {
        let tableResults = res.data?.records.map((obj) => {
          obj.is_active = obj.is_active ? 0 : 1;
          return obj;
        });
        let vendorDetails = tableResults;
        this.setState({
          renderList: vendorDetails,
          tableListSorted: tableResults,
          totalRows: res.data?.total_records_count,
          isLoading: false,
          isSubmit: false,
        });
      })
      .catch((err) => {
        console.log("err", err);
        this.setState({ isLoading: false, isSubmit: false });
      });
  };

  getPosition = (val) => {
    switch (val) {
      case "Inactive":
        return 0;
      case "Name":
        return 1;
      case "Tax ID":
        return 2;
      case "AP Number":
        return 3;
      case "Created On":
        return 6;
    }
  };

  getColumnName = (val) => {
    switch (val) {
      case "Inactive":
        return "is_active";
      case "Name":
        return "vendor_name";
      case "Tax ID":
        return "tax_id";
      case "AP Number":
        return "ap_number";
      case "Created On":
        return "created_at";
    }
  };

  sortColumn = (val) => {
    let Position = this.getPosition(val);
    let col_name = this.getColumnName(val);
    let order = null;
    let config = JSON.parse(JSON.stringify(this.state?.config));
    if (this.state.a_z && config?.headings[Position]?.icon != "") {
      if (config?.headings[Position]?.icon == "sort-alpha-up") {
        config?.headings.map((item) => {
          item.icon = "";
        });
        config.headings[Position].icon = "sort-alpha-down";
      } else {
        config?.headings.map((item) => {
          item.icon = "";
        });
        config.headings[Position].icon = "sort-alpha-up";
      }
      order = "desc";
      this.setState({ a_z: false });
    } else {
      config?.headings.map((item) => {
        item.icon = "";
      });
      this.setState({ a_z: true });
      config.headings[Position].icon = "sort-alpha-up";
      order = "asc";
    }
    this.setState(
      { sortBy: col_name, sortOrder: order, config, page: 1 },
      () => {
        this.getVendorDetails();
      }
    );
  };

  handleAddNewItem = () => {
    this.setState({
      modifyVendor: !this.state.modifyVendor,
      selectedVendor: -1,
    });
  };
  addNewVendor = () => {
    this.mode = "new";
    this.setState({
      modifyVendor: !this.state.modifyVendor,
      selectedVendor: -1,
    });
  };

  onFilterChange = (filter, value) => {
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        [filter]: value,
      },
    }));
  };

  onKeyUpChange = (filter, value) => {
    this.setState({ page: 1 });
    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          [filter]: value,
          page_count: this.state.rowsPerPage,
          page_no: 1,
        },
      }),
      () => {
        this.getVendorDetails();
      }
    );
  };
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage + 1 }, () => {
      this.getVendorDetails();
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState(
      {
        rowsPerPage: event.target.value,
        page: 1,
      },
      () => {
        this.getVendorDetails();
      }
    );
  };

  onSave = () => {
    this.setState({ modifyVendor: false });
    this.getVendorDetails();
  };

  handleDelCallBack = (id, dataItem) => {
    this.deleteItem = dataItem;
    this.setState({ isMessageModalOpen: true });
  };

  onModalConfirm = () => {
    let item = { ...this.deleteItem };
    item.is_deleted = 1;
    this.postData(item);
  };
  onErrorModalConfirm = () => {
    this.setState({
      isErrorMessageModalOpen: false,
    });
    this.deleteItem = null;
  };

  render() {
    return (
      <div>
        {this.state.modifyVendor && (
          <Modal
            open={this.state.modifyVendor || false}
            modalName={"Vendor"}
            comfirmModalTitle={
              this.mode === "new" ? "New Vendor" : "Modify Vendor"
            }
            onClose={this.handleInlineEdits}
            vendor={this.state.selectedVendor}
            onSave={this.onSave}
          />
        )}
        <MDBContainer className="vendorsContainer">
          <MDBRow>
            <MDBCol md={2} className="search">
              <IconTextField
                placeholder={"Search by Vendor Name "}
                value={this.state?.filters?.vendor_name}
                onChange={(e) =>
                  validateCharctersNumberSpecialCharOnly(e.target.value) &&
                  this.onFilterChange(
                    "vendor_name",
                    e.target.value === null || e.target.value === ""
                      ? null
                      : e.target.value
                  )
                }
                onKeyUp={(e) => {
                  if (e.keyCode === 13) {
                    this.onKeyUpChange(
                      "vendor_name",
                      e.target.value === null || e.target.value === ""
                        ? null
                        : e.target.value
                    );
                  }
                }}
                icon={"search"}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow className="table-striped">
            {this.state?.isLoading ? (
              <div className="ContentLoader">
                <CircularProgress />
              </div>
            ) : (
              <>
                <TableComponent
                  list={this.state.tableListSorted || []}
                  className={"clearencRecord"}
                  config={this.state?.config}
                  isLoading={this.state?.isLoading || false}
                  sortCallback={this.sortColumn}
                  stickyHeader={true}
                  editCallback={this.handleInlineEdits.bind(this)}
                  saveCallback={this.saveInlineEdits.bind(this)}
                  handleAddNewItem={this.handleAddNewItem.bind(this)}
                  addItemToList={true}
                  handleDelCallBack={this.handleDelCallBack}
                />

                <div className="shows-pagination d-flex justify-content-between">
                  <BasicButton
                    text="New"
                    icon={"plus"}
                    onClick={this.addNewVendor}
                  />
                  <TablePagination
                    component="div"
                    count={this.state?.totalRows}
                    rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                    page={this.state?.page - 1}
                    onChangePage={this.handleChangePage}
                    rowsPerPage={this.state?.rowsPerPage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </div>
              </>
            )}
          </MDBRow>
        </MDBContainer>
        {this.state?.isMessageModalOpen && (
          <MessageModal
            open={this.state.isMessageModalOpen}
            showCustom={true}
            message={Message.deleteAdmin}
            title={"Confirm Deletion"}
            primaryButtonText={"Ok"}
            secondaryButtonText={"Cancel"}
            hideCancel={false}
            onConfirm={() => this.onModalConfirm()}
            handleClose={(e) => this.setState({ isMessageModalOpen: false })}
          />
        )}
        {this.state?.isErrorMessageModalOpen && (
          <MessageModal
            open={this.state.isErrorMessageModalOpen}
            showCustom={true}
            message={Message.deleteAdminError}
            title={"Message"}
            primaryButtonText={"Ok"}
            hideCancel={true}
            onConfirm={() => this.onErrorModalConfirm()}
            handleClose={(e) =>
              this.setState({ isErrorMessageModalOpen: false })
            }
          />
        )}
      </div>
    );
  }
}

export default withUserContext(Versions);
